import {BaseModel} from '../BaseModel';


export class Player extends BaseModel {
  name: string;
  surname: string;
  username: string;
  role: string;
  avatar: string;
  email: string;
  phone: string;


constructor() {
    super();
  }

  isModerator() {
    return this.role === 'MODERATOR';
  }

  

  static fromObject(o: Object) {
    if (o === null)
      return null;
    let u = new Player();
    Object.assign(u, o);
    return u;
  }

}
