import {Injectable} from '@angular/core';
import {HttpRequest} from '@angular/common/http';
import {HttpHandler} from '@angular/common/http';
import {HttpEvent} from '@angular/common/http';
import {Observable, throwError, of} from 'rxjs';
import {HttpInterceptor} from '@angular/common/http';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import {catchError, map, tap} from "rxjs/internal/operators";
import {HttpErrorResponse} from "@angular/common/http";
import {Router} from "@angular/router";
import {ToastController} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";
import {AuthService} from "../auth.service";
import {CredentialsResponse} from "../../models/user/credentials.response.model";
import {Exception} from "../../exceptions/exception";
import {StorageService} from "../storage.service";


@Injectable()
export class InterceptorProvider implements HttpInterceptor {
    constructor(public auth: AuthService,
                private storageSRV: StorageService, private router: Router,
                protected toastController: ToastController,
                protected translate: TranslateService,
                private authenticationService: AuthService) {}


    async presentToastWithOptions(message: string) {
        const toast = await this.toastController.create({
            message: message+this.translate.instant('NETWORK.DONE'),
            position: 'bottom',
            buttons: [
                {
                    text: 'Done',
                    role: 'cancel',
                    handler: () => {
                        console.log('Cancel clicked');
                    }
                }]
        });
        //toast.present();
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        //console.log("INTERCEPTOR!!!!!!! REQUEST:", request);
        const credResp = this.storageSRV.get(this.auth.AUTH_DATA);


                    if (credResp && credResp.token) {
                        request = this.addToken(request, credResp.token);
                        //console.log("INTERCEPTOR!!!!!!! REQUEST.NOU:", request);
                    }

                    return next.handle(request).pipe(
                      map((event: HttpEvent<any>)=>{
                          //console.log(event);
                          return event;
                      }),
                      catchError((error: HttpErrorResponse) => {
                          console.log("Interceptor !!!!!error", error);
                          if (error.status==401) {
                              this.presentToastWithOptions(this.translate.instant('COMMON.SESSION_TIMEOUT'));
                              this.authenticationService.logout();
                              //this.router.navigateByUrl('/login');
                          }
                          return throwError(error);
                      })
                    )
    }



    private addToken(request: HttpRequest<any>, token: string) {

        request = request.clone({
            setHeaders: {
                Authorization: `BEARER ${token}`
            }
        });
        return request;
    }
}
