import { Injectable } from '@angular/core';
import {EndpointService} from "./endpoint.service";
import {HttpClient} from "@angular/common/http";
import {Search} from "../interfaces/api-search.model";
import {Observable} from "rxjs";
import {Lap} from "../models/lap/lap.model";
import {Round} from "../models/lap/round.model";


@Injectable({
  providedIn: 'root'
})
export class LapService {

  constructor(private http: HttpClient, private endpoints: EndpointService) { }


  getCurrentLap(idLeague: string, currentLap: string): Observable<Search<Lap>> {
    let url = this.endpoints.searchLaps;

    let query = {
      query: {"league._id": idLeague, "name": currentLap}
    };

    return this.http.post<Search<Lap>>(url, query);
  }

  getLaps(idLeague: string, idUser?: string, lap?: string, isPrevia?:boolean): Observable<Search<Lap>> {
    let url = (isPrevia?this.endpoints.searchPrevia: this.endpoints.searchLaps);

    let criteria = [];
    criteria.push({"p1._id": idUser });
    criteria.push({"p2._id": idUser });
    criteria.push({"p3._id": idUser });
    criteria.push({"p4._id": idUser });

    let _query;

    if (idUser!=null)  {
      _query = {
        "query": {
          "league._id": idLeague,
          "name": lap,
          "$or": criteria
        },
        sortBy: {"group": 1}};
    }
    else {
      _query = {
        "query": {
          "league._id": idLeague,
          "name": lap
        },
        sortBy: {"group": 1}};
    }


    return this.http.post<Search<Lap>>(url, _query);
  }

  getRound(idLeague: string, round: string): Observable<Search<Round>> {
    let url = this.endpoints.searchRounds;


    let _query = {
        "query": {
          "league._id": idLeague,
          "name": round
        }
    };

    return this.http.post<Search<Round>>(url, _query);

  }


  getRounds(idLeague: string): Observable<Search<Round>> {
    let url = this.endpoints.searchRounds;


    let _query = {
      "query": {
        "league._id": idLeague,
      },
      sortBy: {dateStart: -1}
    };

    return this.http.post<Search<Round>>(url, _query);

  }

  updateLap(lap: Lap): Observable<void> {
    let url = `${this.endpoints.updateLap}/${lap._id}`;

    return this.http.put<void>(url, lap);

  }


}
