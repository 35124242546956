import { Injectable } from '@angular/core';
import {Observable, BehaviorSubject, of} from "rxjs";
import {HttpClient} from "@angular/common/http";

import {EndpointService} from "./endpoint.service";
import {Credentials} from "../models/user/credentials.model";
import {Platform} from "@ionic/angular";
import {CredentialsResponse} from "../models/user/credentials.response.model";
import {Player} from "../models/user/player.model";
import {UserToken} from "../models/user/user-token.model";
import {StorageService} from "./storage.service";


@Injectable({
  providedIn: 'root'
})
export class AuthService  {

  public AUTH_DATA = 'AUTH_DATA';

  authenticationState = new BehaviorSubject(false);

  private userToken = new UserToken();
  private currentUser = new Player();
  private lastUpdate: Date;
  public token;

  constructor(private storageSRV: StorageService, private plt: Platform,
              private endpoint: EndpointService,
              private http: HttpClient) {

   //this.userToken = UserToken.fromObject(this.storageSRV.get('USER_TOKEN'));
    this.plt.ready().then(() => {
       this.checkToken();
    });
  }

  checkToken() {
    /*
    this.storageSRV.get(this.AUTH_DATA).then(res => {
      console.log('checkTOKEN', res);
      if (res) {
        this.authenticationState.next(true);
        console.log('TRUE!!!');
      }
    });*/
    this.token = this.storageSRV.get(this.AUTH_DATA);
    if (this.token!=null) this.authenticationState.next(true);
  }

  getCurrentUser(): Player {
    return this.currentUser;
  }

  public setCurrentUser(user: Player) {
    this.currentUser = user;

  }

  logout() {
    this.storageSRV.remove(this.AUTH_DATA);
      this.authenticationState.next(false);
  }

  login(credentialsResponse: CredentialsResponse) {
    this.storageSRV.save(this.AUTH_DATA, credentialsResponse);
    //console.log("login", this.AUTH_DATA);
    //console.log("cred", credentialsResponse);
    this.token = credentialsResponse;
    this.authenticationState.next(true);
  }

  isAuthenticated() {
    return this.authenticationState.value;
  }

  doLogin(username: string, password: string): Observable<CredentialsResponse> {
    const url = this.endpoint.loginUser;
    return this.http.post<CredentialsResponse>(url, new Credentials(username, password));
  }

  public getToken() {
    return   this.storageSRV.get(this.AUTH_DATA);
  }

  getAuthToken(): string {
    let token = this.getToken();

    if (token != null) {
      return token.token;
    }
    return null;
  }


}

interface LoginResponse {
  role: string;
  token: string;
}



