import { Injectable } from '@angular/core';
import {Search} from "../interfaces/api-search.model";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {EndpointService} from "./endpoint.service";
import {League} from "../models/league/league.model";

@Injectable({
  providedIn: 'root'
})
export class LeagueService {

  protected currentLeague: string;
  constructor(private http: HttpClient, private endpoints: EndpointService) {
    this.currentLeague="65104db2cd83d2000cfeed12";
  }


  getLeagues(id: string): Observable<Search<League>> {
    let url = this.endpoints.searchLeagues;

    let query = {
      query: {"players._id": id, "active": true}
    };

    return this.http.post<Search<League>>(url, query);
  }

  getCurrentLeague(): string {
    return this.currentLeague;
  }

  setCurrentLeague(newLeague: string) {
    this.currentLeague = newLeague;
  }

  getLeague(id: string): Observable<League> {
    let url = `${this.endpoints.getLeague}/${id}`;

    return this.http.get<League>(url);
  }

  ranking(id: string): Observable<any> {
    let url = `${this.endpoints.ranking}/${id}`;

    return this.http.get<any>(url);
  }

  rankingByUser(leagueId: string, id: string): Observable<any> {
    let url = `${this.endpoints.rankingByUser}/${leagueId}/${id}`;

    return this.http.get<any>(url);
  }


}
