export class UserToken {
  id: string;
  username: string;
  role: string;
  token: string;

  constructor() {
  }

  static fromToken(authToken: string): UserToken {
    let token = authToken.split('.')[1];
    let parsedToken = JSON.parse(atob(token));

    let userToken = new UserToken();
    userToken.id = parsedToken._id;
    userToken.username = parsedToken.username;
    userToken.role = parsedToken.role;
    userToken.token = authToken;

    return userToken;
  }

  static fromObject(o: Object) {
    if (o === null)
      return null;
    let userToken = new UserToken();
    Object.assign(userToken, o);
    return userToken;
  }
}
