import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {


  constructor() {
  }

  /**
   * Store an object (value) by a given key
   * @param key
   * @param value
   */
  public save(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  /**
   * Retrieve an object by a given key. If retrieved object is null can be enforced an optional default value
   * @param key
   * @param def
   * @returns {any}
   */
  public get(key: string, def?: any) {
    let value = JSON.parse(localStorage.getItem(key));
    if (value != null)
      return value;
    if (def != null) {
      return def;
    }
    return value;
  }

  /**
   * Clear StorageService values.
   *
   * Primarily used on logout function to cleanup application
   */
  public clear() {
    localStorage.clear();
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }
}
