import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import {Router} from "@angular/router";
import {LapService} from "./services/lap.service";
import {Round} from "./models/lap/round.model";
import {LeagueService} from "./services/league.service";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public appPages = [
    {
      title: 'Info',
      url: '/info',
      icon: 'home'
    },
    {
      title: 'Ranking',
      url: '/ranking/',
      icon: 'podium'
    },
    {
      title: 'Enviament de resultats',
      url: '/login',
      icon: 'log-in'
    },
    {
      title: 'Edicions anteriors',
      url: '/archive',
      icon: 'archive'
    },
  ];
  //public labels = ['Volta2','Volta1','PRÈVIA'];
  public rounds: Round[] = [];
  protected leagueId: string;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private router: Router,
    private statusBar: StatusBar,
    private lapsService: LapService,
    private leagueService: LeagueService
  ) {
    this.initializeApp();

    this.leagueId =  this.leagueService.getCurrentLeague();
    this.appPages[1].url+=this.leagueId;



    this.lapsService.getRounds(this.leagueId).subscribe(
        (data) => {
          console.log("Rounds:", data);
          this.rounds = data.result;
        }
    )


  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
  }

  openLap(id) {
    this.router.navigateByUrl("/lap/matches/"+this.leagueId+"/"+id);
    //navigate(["/lap", id]);
  }
}
