export class Credentials {
  login: string;
  password: string;

  constructor (login: string, password: string) {
    this.login = login;
    this.password = password;
  }

  validate(): string[] {
    let errors: string[] = [];

    if (!this.login)
      errors.push('MISSING_LOGIN');
    if (!this.password)
      errors.push(('MISSING_PASSWORD'));

    return errors;
  }

}
