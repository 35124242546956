import { Injectable } from '@angular/core';
import {Platform} from "@ionic/angular";

@Injectable({
  providedIn: 'root'
})
export class EndpointService {

  private prefix: string;
  server: string;

  loginUser: string;
  getPlayer: string;

  searchLaps: string;
  searchLeagues: string;
  searchPrevia: string;
  searchMatches: string;
  searchPlayers: string;

  addMatch: string;
  updateMatch: string;
  updateLap: string;

  getRound: string;
  searchRounds: string;

  getLeague: string;
  ranking: string;
  rankingByUser: string;


  constructor(private platform: Platform) {
    this.configurePrefix();
    this.configureRemoteServer();
    this.configureEndpoints();
  }

  private configurePrefix() {
    this.prefix = '';
  }

  private configureRemoteServer() {
    /*
    if (this.platform.is('cordova') || location.hostname === 'preveo.vinci-energies.es') {
      this.server = 'https://preveo-api.vinci-energies.es';
    } else if (location.hostname === 'preveo-app.aaaida.com') {
      this.server = 'https://preveo-api.aaaida.com';
    } else if (location.hostname === 'preveo.127.0.0.1.xip.io') {
      this.server = 'http://localhost:40000';
    } else {
      this.server = 'https://preveo-api.vinci-energies.es';
    }*/

  //  if (location.hostname == 'http://preveo2.aaaida.com//') {
      //this.server = 'http://preveo2-api.aaaida.com:40000';
  //  }
    //else
    //this.server = 'http://localhost:40000';
    this.server = 'https://padelpmt-api.upc.edu';

  }

  private configureEndpoints() {
    this.loginUser = `${this.server}${this.prefix}/auth/login`;
    this.getPlayer = `${this.server}${this.prefix}/player`;
    this.searchPlayers = `${this.server}${this.prefix}/players`;

    this.getLeague = `${this.server}${this.prefix}/league`;
    this.searchLaps = `${this.server}${this.prefix}/laps`;
    this.updateLap = `${this.server}${this.prefix}/lap`;

    this.searchLeagues = `${this.server}${this.prefix}/leagues`;
    this.searchPrevia = `${this.server}${this.prefix}/lapsPrevias`;
    this.searchMatches = `${this.server}${this.prefix}/matches`;

    this.addMatch = `${this.server}${this.prefix}/match`;
    this.updateMatch = `${this.server}${this.prefix}/match`;

    this.getRound  = `${this.server}${this.prefix}/round`;
    this.searchRounds  = `${this.server}${this.prefix}/rounds`;

    this.ranking  = `${this.server}${this.prefix}/ranking`;
    this.rankingByUser=`${this.server}${this.prefix}/ranking-user`;


  }

}