import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AuthGuardService} from "./services/auth-guard.service";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'info',
    pathMatch: 'full'
  },
  {
    path: 'archive',
    loadChildren: () => import('./pages/archive/archive.module').then( m => m.ArchivePageModule)
  },
  {
    path: 'info',
    loadChildren: () => import('./pages/info/info.module').then( m => m.InfoPageModule)
  },
  {
    path: 'lap',
    loadChildren: () => import('./pages/lap/lap.module').then( m => m.LapPageModule)
  },
  {
    path: 'ranking/:id',
    loadChildren: () => import('./pages/ranking/ranking.module').then( m => m.RankingPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'edit-lap-tab',
    loadChildren: () => import('./pages/edit-lap-tab/edit-lap-tab.module').then( m => m.EditLapTabPageModule)
  },
  {
    path: 'ranking-user/:leagueId/:userId',
    loadChildren: () => import('./pages/ranking-user/ranking-user.module').then( m => m.RankingUserPageModule)
  }
];

/*
 {
 path: 'edit-lap-tab',canActivate: [AuthGuardService],
 loadChildren: () => import('./pages/edit-lap-tab/edit-lap-tab.module').then( m => m.EditLapTabPageModule)
 },

 */

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
